import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { Typography } from "@material-ui/core";
import { HeadlineText } from "site-ui/src/components/HeadlineText";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { SubHeader } from "site-ui/src/components/SubHeader";
import { GatsbyImage } from "gatsby-plugin-image";
import { Section } from "site-ui/src/components/Section";
import Box from "@material-ui/core/Box";
import { options } from "twill-contentful-text-render";
import styled from "styled-components";
import { backgroundGrey } from "site-ui/colors";
import { Select } from "site-ui/src/components/Select";
import { Button } from "site-ui/src/components/Button";
import axios from "axios";
import { Trans } from "twill-net-localization";
import { renderRichText } from "twill-contentful-text-render";
import { useTranslation } from "twill-net-localization";

const Chip = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.bgColor || backgroundGrey};
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  margin-right: 16px;
  font-weight: bold;
  font-size: 14px;
`;

const PaginationLink = (item) => {
  switch (item.type) {
    case "page":
      return <Link to={`/news/${item.page}`}>{item.page}</Link>;
    default:
      return null;
  }
};

export default ({ data, pageContext }) => {
  const {
    categories: { nodes: categories },
    allContentfulKnowledgeHubArticle: { totalCount, nodes },
  } = data;
  const { pagination } = pageContext;
  const [totalArticles, setTotalArticles] = useState(200);
  const [articles, setArticles] = useState(nodes);
  const [paginationState, setPaginationState] = useState({
    ...pagination,
    isLoading: false,
  });
  const [category, setCategory] = useState(false);
  const goToPage = (page) => {};
  const [t] = useTranslation();

  const loadMore = async () => {
    setPaginationState({ ...paginationState, isLoading: true });
    const {
      data: { nodes, ...newPaginationState },
    } = await axios.get(
      `/gatsby_assets/static/paginated-data/${paginationState.collection_id}/${paginationState.nextPage.id}.json`
    );
    setArticles([...articles, ...nodes]);
    setPaginationState({
      ...paginationState,
      ...newPaginationState,
      isLoading: false,
    });
    window &&
      window.history.replaceState(
        {},
        pageContext.seoMeta.title,
        `/news/${newPaginationState.index}`
      );
  };
  return (
    <PageWrapper>
      <Section>
        <HeadlineText
          align="left"
          headerText={
            <Link to="/news">
              <strong>
                <Trans i18nKey="knowledgeHub.newsAndUpdates" values={{}}>
                  News and updates
                </Trans>
              </strong>
            </Link>
          }
          subheader={
            <SubHeader
              items={[
                <span>
                  <Trans
                    i18nKey="knowledgeHub.totalArticles"
                    values={{ count: totalCount }}
                    defaults="{{count}} articles"
                  ></Trans>
                </span>,
              ]}
            />
          }
        />
        <Box mt={2}>
          <Typography variant="subtitle1">
            <Trans i18nKey="knowledgeHub.exploreStoriesAndUpdates" values={{}}>
              Explore Twill stories and (product) updates
            </Trans>
          </Typography>
        </Box>
      </Section>
      <Section pt={0}>
        <Box maxWidth={250} mb={8}>
          <Select
            options={[
              { label: "All categories", value: false },
              ...categories.map((category) => ({
                label: category.title,
                value: category.seoMeta.slug,
              })),
            ]}
            placeholder={"All Categories"}
            onChange={(e) => {
              setCategory(e.value);
            }}
            isSearchable={false}
          />
        </Box>

        {articles
          .filter(
            (article) => !category || article.category.seoMeta.slug == category
          )
          .slice(0, totalArticles)
          .map((article) => (
            <article style={{ marginBottom: "107px", maxWidth: "670px" }}>
              <Box fontSize={`14px`}>
                <Chip bgColor={article.category.color}>
                  {article.category.title}
                </Chip>
                {article.publishedDate || article.createdAt}
              </Box>
              <Typography variant="h3">{article.title}</Typography>
              <Box my={3}>
                {renderRichText(article.localContent.excerpt, options)}
              </Box>
              <Box maxWidth={640}>
                {article.featuredImage && (
                  <GatsbyImage
                    style={{
                      boxShadow: `0 5px 20px 0 rgba(0, 0, 0, 0.16)`,
                      borderRadius: "4px",
                    }}
                    {...article.featuredImage.localFile?.childImageSharp}
                  />
                )}
              </Box>
              <Box fontWeight="bold" mt={4}>
                <Link
                  to={`/news/${article.category.seoMeta.slug}/${article.seoMeta.slug}`}
                >
                  Read more &rarr;
                </Link>
              </Box>
            </article>
          ))}
        {paginationState.hasNextPage && (
          <Button
            variant={"secondary"}
            disabled={paginationState.isLoading}
            onClick={loadMore}
          >
            {t("common.loadMore", "Load more")}
          </Button>
        )}
      </Section>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query NewsMainPageQuery(
    $locale: String
    $skip: Int!
    $limit: Int!
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    categories: allContentfulKnowledgeHubCategory(
      filter: { featureInNews: { eq: true }, node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        seoMeta {
          slug
          title
        }
      }
    }
    allContentfulKnowledgeHubArticle(
      filter: {
        category: { featureInNews: { eq: true } }
        node_locale: { eq: $locale }
      }
      sort: { fields: [publishedDate, createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        title
        seoMeta: localContent {
          slug
          title: seoTitle
          description: seoDescription
        }
        localContent {
          excerpt {
            raw
          }
        }
        createdAt(formatString: "Do MMMM YYYY", locale: $locale)
        publishedDate(formatString: "Do MMMM YYYY", locale: $locale)
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 400
                width: 640
                placeholder: BLURRED
                transformOptions: { fit: COVER, cropFocus: ATTENTION, trim: 1 }
                layout: CONSTRAINED
              )
            }
          }
        }
        category {
          title
          color
          seoMeta {
            slug
            title
          }
        }
      }
    }
  }
`;
