import styled, { css } from "styled-components";
import { backgroundGrey, white } from "site-ui/colors";

export interface PageWrapperProps {
  primaryColor?: string;
  secondaryColor?: string;
  variant?: "empty" | "striped";
  fullHeight?: boolean;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  ${(props) =>
    props.variant !== "empty" &&
    css`
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='1017' height='493' viewBox='0 0 1017 493'%3E%3Cdefs%3E%3ClinearGradient id='fnswnczoia' x1='64.784%25' x2='0%25' y1='47.988%25' y2='61.726%25'%3E%3Cstop offset='0%25' stop-color='%23FFF'/%3E%3Cstop offset='100%25' stop-color='%23FFF' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-opacity='.8' fill-rule='evenodd' opacity='.727'%3E%3Cg fill='url(%23fnswnczoia)'%3E%3Cpath d='M0 492.994L1018 0 1018 493z' transform='rotate(-180 509 246.5)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='1263' height='613' viewBox='0 0 1263 613'%3E%3Cdefs%3E%3ClinearGradient id='zd3ka4h8qa' x1='64.784%25' x2='0%25' y1='47.985%25' y2='61.741%25'%3E%3Cstop offset='0%25' stop-color='%23FFF'/%3E%3Cstop offset='100%25' stop-color='%23FFF' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-opacity='.8' fill-rule='evenodd' opacity='.823'%3E%3Cg fill='url(%23zd3ka4h8qa)' transform='translate(-656 -318)'%3E%3Cpath d='M654 930.993L1919 318 1919 931z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
        linear-gradient(
          to bottom,
          ${({ primaryColor }) => primaryColor || backgroundGrey},
          ${({ primaryColor }) => primaryColor || backgroundGrey} 80%,
          ${({ secondaryColor }) => secondaryColor || white}
        );
      background-color: ${({ secondaryColor }) => secondaryColor || white};

      background-size: 90%, 100%, 100% 80vh;
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position: left top,
        right ${({ fullHeight }) => (fullHeight ? "bottom" : "400px")}, left top;
    `}
  padding-top: 72px;
  @media (min-width: 640px) {
    padding-top: 104px;
    background-size: 1017px 493px, 1265px 613px, 100% 800px;
  }
`;
