import React from "react";
import styled from "styled-components";
import { textColor } from "site-ui/colors";

type SubHeaderProps = {
  items: JSX.Element[];
};

export const SubHeader = ({ items = [] }: SubHeaderProps) => {
  return <Wrapper>{items.map((item) => item)}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  flex-wrap: wrap;
  & > * {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin: 0 16px 0 0;
      padding: 0 16px 0 0;
    }
    position: relative;
    white-space: nowrap;
    &:not(:last-child):after {
      content: "";
      position: absolute;
      right: -1px;
      border-right: 1px solid ${textColor};
      height: 1em;
    }
  }
`;
