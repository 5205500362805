import React, { ReactNode } from "react";
import styled from "styled-components";
import { textColor, darkBlue } from "site-ui/colors";
import Box, { BoxProps } from "@material-ui/core/Box";

export interface HeadlineTextProps extends BoxProps {
  icon?: JSX.Element;
  headerText: ReactNode;
  subheader?: ReactNode;
  align?: "left" | "center";
}

export const HeadlineText = ({
  icon,
  headerText,
  align = "center",
  subheader,
  ...props
}: HeadlineTextProps) => {
  return (
    <Wrapper align={align} {...props}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Header>{headerText}</Header>
      {subheader && subheader}
    </Wrapper>
  );
};

const Wrapper = styled(Box)<{ align: string }>`
  display: flex;
  flex-direction: column;
  max-width: 828px;
  text-align: left;
  box-sizing: border-box;
  a {
    color: ${textColor};
  }
  ${(props) =>
    props.align == "center" && "margin-left: auto; margin-right: auto"};
  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: ${(props) => props.align || "center"};
    align-items: ${(props) => props.align || "center"};
  }
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
  img {
    width: 100%;
  }
  @media (min-width: 1024px) {
    margin-bottom: 16px;
  }
`;

const Header = styled.h1`
  font-size: 26px;
  line-height: 1.4;
  margin-bottom: 16px;
  color: ${darkBlue};
  strong {
    font-weight: bold;
  }
  @media (min-width: 480px) {
    font-size: 36px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 32px;
    font-size: 44px;
  }
`;
