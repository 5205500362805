import React from "react";
import SelectBase from "react-select";
import { darkBlue, white } from "site-ui/colors";
import styled from "styled-components";
import { Chevron } from "../../icons/Chevron";

const Control = ({ innerProps, isDisabled, prefix, children, ...props }) => {
  return !isDisabled ? (
    <ControlStyled {...innerProps} {...props}>
      {children}
    </ControlStyled>
  ) : null;
};

const ControlStyled = styled.div`
  width: 100%;
  background-color: ${white};
  box-sizing: border-box;
  border: solid 1px #d0d3da;
  border-radius: 4px;
  height: 56px;
  display: flex;
  flex-stretch: 3;
  justify-items: stretch;
  align-items: center;
  padding: 0 12px;
  ${({ isFocused }) => isFocused && `border: 1px solid ${darkBlue};`}
`;

const IndicatorsContainer = (props) => <div {...props} />;

const DropdownIndicator = () => <Chevron width={24} height={24} />;

export const Select = ({ options, ...props }) => (
  <SelectBase
    components={{
      Control,
      DropdownIndicator,
      IndicatorsContainer,
    }}
    options={options}
    {...props}
  />
);
